import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from '@/store/Index.js';
import 'bootstrap';
import vue3GoogleLogin from 'vue3-google-login';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueGtag from 'vue-gtag'
import { library } from '@fortawesome/fontawesome-svg-core';

import { faBullhorn,faLocationPin,faPlay,faChevronRight,faChevronLeft,faArrowDown,faEnvelope,faPhone,faBars,faTicket } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker'
// import 'bootstrap/dist/js/bootstrap.min.js';
const app = createApp(App)

library.add(faBullhorn,faLocationPin,faPlay,faChevronRight,faChevronLeft,faArrowDown,faEnvelope,faPhone,faBars,faTicket)
app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueGtag,{config: { id: 'G-6JWW0CXV4K' }});
app.use(vue3GoogleLogin, {
    clientId: '911242072574-s136pulkh0mdrnbbtic68244gsk4mr0i.apps.googleusercontent.com'
  })

app.mount('#app');

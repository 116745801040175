<template>
  <div :class="customer?'metaportal_fn_wallet_closer ready':' metaportal_fn_wallet_closer ready active'">
  <div class="loaderWraper"><img :src="imgPath+'assets/img/loader.gif'" width="100"></div>
</div> 
  <SiteProgress></SiteProgress>
  <SiteHeader ref="headComp" @userLoaded="getUser"></SiteHeader>
  <div class="container-fluid small" style="background-color: rgb(242, 242, 242);">
    <section class="content-section padtb-hed">
      <div class="container contbg">
        <div class="row">
          <div class="col-sm-4 col-md-4">
            <h3 class="texh3" style="font-size: 22px; padding-bottom: 20px">
              Your Profile details
            </h3>
            <div class="col-md-12 nopad">
              <div class="">Your Name</div>
              <div class="tecnam">{{customer.name}}</div>
              <div class="">Your Email</div>
              <div class="tecnam">{{customer.email}}</div>
              <div class="">Your Mobile</div>
              <div class="tecnam">{{customer.mobile}}</div>
              <div class="">Status</div>
              <div class="tecnam blu">Active</div>
              <div class="">Want to Logout</div>
              <div class="tecnam"><a style="color: #cf181f !important;" href="#" @click="logout">Logout</a></div>
            </div>
          </div>
          <aside class="col-sm-8 col-md-8 col-sm-push-0 sidebar borleft">
            <div class="">
              <div class="clearfix"></div>
              <div class="col-sm-12 nopad">
                <div class="tabbable-panel">
                  <div class="tabbable-line">
                    <ul class="nav nav-tabs">
                      <li :class="currentTab==0?'active':''">
                        <a href="#" @click="currentTab=0" class="fon17 padsmri-le">
                          Edit Profile
                        </a>
                      </li>
                      <li :class="currentTab==1?'active':''">
                        <a href="#" @click="currentTab=1" class="fon17 padsmri-le">
                          Change Password</a
                        >
                      </li>
                      <li :class="currentTab==2?'active':''"> 
                        <a href="#" @click="currentTab=2"  class="fon17 padsmri-le">
                          Booking History
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div :class="currentTab==0?'tab-pane active':'tab-pane'"  >
                        <form name="frmCust" @submit.prevent="updateCust">
                          <input type="hidden" name="code" v-model="customer.loginCode">
                        <div class="row nopad">
                          <!-- <div class="col-md-6 pad7">
                            <label class="tecname padtop14"
                              >First Name *
                            </label>
                            <input class="fulwid pad7" type="text" />
                          </div> -->
                          <div class="col-md-6 pad7">
                            <label class="tecname padtop14">Name </label>
                            <input class="fulwid pad7" type="text" v-model="customer.name" />
                          </div>
                          <div class="col-md-6 pad7">
                            <label class="tecname padtop14">Mobile No *</label>
                            <input class="fulwid pad7" type="text" v-model="customer.mobile" />
                          </div>
                          <div class="col-md-6 pad7">
                            <label class="tecname padtop14">Email *</label>
                            <input class="fulwid pad7" type="text" v-model="customer.email" />
                          </div>
                          <div class="col-md-6 pad7 marg15">
                          <button
                            type="submit"
                            class="btn btn-danger btun marg15 nextBtn bk fulwid"
                          :disabled="customer.clicked">{{customer.clicked?'Processing..':'Save'}}</button>
                        </div>
                        </div>
                        <div class="text-warning" v-html="this.customer.error"></div>
                        </form>
                      </div>

                      <div :class="currentTab==1?'tab-pane active':'tab-pane'">
                        <form name="frmPwd" @submit.prevent="changePwd">
                        <div class="row">
                          <div class="col-md-6 pad7">
                            <label class="tecname padtop14"
                              >New Password *
                            </label>
                            <input class="fulwid pad7" type="password" v-model="pwd.pwd" />
                          </div>
                          <div class="col-md-6 pad7">
                            <label class="tecname padtop14"
                              >Retype Password *</label
                            >
                            <input class="fulwid pad7" type="Password" v-model="pwd.confirm" />
                          </div>
                        </div>
                        <div class="col-md-12 pad7">
                          <button type="submit" class="btn btn-danger marg15 fulwid"
                          :disabled="pwd.clicked">{{pwd.clicked?'Processing':"Save"}}</button>
                          <div class="text-warning" v-html='pwd.error'></div>
                        </div>
                      </form>
                      </div>

                      <div :class="currentTab==2?'tab-pane active':'tab-pane'">
                        <div v-if="bookings">
                        <div class="col-md-12 nopad borbot marg10"  v-for="(booking,index) in bookings" :key="index">
                          <div class="pull-left">
                            <span class="tecnam font15">
                              {{booking.stime}}</span>
                            <div class="font25">{{booking.co_movie}}</div>
                            <div class="">Seat Nos : <span class="text-secondary">{{booking.co_seat}}</span></div>
                            <div class="">Screen : <span class="text-secondary">{{booking.screen}}</span></div>
                            <div class="">Total Fare : <span class="blu"> ₹ {{booking.amount}}</span></div>
                            <div ></div>
                          </div>
                          <div class="pull-right text-right" v-if="booking.avbl">
                            <a class="font13"  href="#" @click="sendMail(booking.res)">
                              MAIL</a
                            ><br />
                            <a class="font13" href="#" @click="this.$router.push('/my-mticket/'+booking.res)"
                              ><i
                                class="fa fa-print padli5"
                                aria-hidden="true"
                              ></i>
                              M Ticket</a
                            >
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                        <div class="alert alert-danger" v-if="!bookings"> No previous bookings to display</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>

      <!-- end container -->
    </section>
  </div>
  
  <SiteFooter></SiteFooter>
   <div :class="'modal-mask'+(showModal?' show':'')" @click="showModal=false"><div class="modal-wrapper"><div class="modal-container" v-html="modalText"></div></div></div>
</template>

<script>
import axios from "axios";
import { URL } from "@/Conf";
import SiteFooter from "../UI/SiteFooter.vue";
import SiteHeader from '../UI/SiteHeader.vue';
import SiteProgress from "../UI/SiteProgress.vue";

export default {
  data:()=>({
      imgPath:process.env.BASE_URL,
      showModal:false,
    modalText:null,
    currentTab: 0,
      bookingHistory:{},
      customer:{},
      bookings:{},
      pwd:{pwd:null,confirm:null,error:null,clicked:null,code:localStorage.getItem("loginCode")},
    }),
  
  components: {
    SiteFooter,
     SiteHeader,
    SiteProgress,
  },
  methods:{
getUser(){
  this.customer=this.$refs.headComp.customer;
},
sendMail(resno){
  this.showModal=true;
  this.modalText="<div class='text-danger'>Please wait</div>";
  var that=this;
  axios.post(URL + "sendMail",{"res":resno},{headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              }}).then((response) => {
                if(response.data.msg)
               {
                this.modalText=response.data.msg;
               }
               setTimeout(function () {
                 that.showModal=false;
                 that.modalText=null;
               },3000)
              });
},
updateCust(){
  this.customer.error=null;
  var valid=true;
  var ermsg="";
  if(!this.customer.name)
  {
    ermsg+='Please enter your name<br>';
  }

    //eslint-disable-next-line
      var emlexp=/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (!emlexp.test(this.customer.email)) {
        valid=false;
        ermsg+='Please enter valid email ID<br>';
      }
      const filter = /^\d{10}$/;
      //eslint-disable-next-line
      if (!filter.test(this.customer.mobile)) {
      valid=false;
      ermsg+="Enter a valid 10 digit mobile number (without leading zero and +91)<br>";
      }
  if(valid)
      {

        this.customer.clicked=true;
        axios.post(URL + "updateCustomer",this.customer,{headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              }}).then((response) => {
        this.customer.clicked=false;
               if(response.data.status=="done")
               {
                this.customer=response.data.customer;
                localStorage.setItem("loginCode",response.data.customer.loginCode);
           }else if(response.data.status=="error")
               {
this.customer.error=response.data.msg;
               }else{
            this.customer.error="Something went wrong";
           }
            }).catch(()=>{
      
            })}else{
              this.customer.error=ermsg;
            }
},
changePwd(){
  var valid=true;
  var ermsg="";
  if(!this.pwd.pwd){
  valid=false;
    ermsg="Password cannot be empty";  
  }
  if(this.pwd.pwd!=this.pwd.confirm){
    valid=false;
    ermsg="Password and confirm password must be identical";
  }
  if(valid)
      {
        this.pwd.clicked=true;
        axios.post(URL + "updatePwd",this.pwd,{headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              }}).then((response) => {
        this.pwd.clicked=false;
               if(response.data.status=="done")
               {
                this.pwd={};
                this.pwd.code=localStorage.getItem("loginCode");
           }else if(response.data.status=="error")
               {
this.pwd.error=response.data.msg;
               }else{
            this.pwd.error="Something went wrong";
           }
            }).catch(()=>{
      
            })}else{
              this.pwd.error=ermsg;
            }
},
logout(){
  localStorage.removeItem("loginCode");
  this.$router.push("/");
},
getBookings(){
axios.get(URL + "bookHistory?code="+localStorage.getItem("loginCode")).then((response) => {
if(response.data.status=="done")
{
  this.bookings=response.data.tickets;
}
})
}
  }
  ,mounted(){
if(!localStorage.getItem("loginCode")){
  this.$router.push("/");
  }
this.getBookings();
}
};
</script>

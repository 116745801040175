<template>
    <SiteProgress></SiteProgress>
    <SiteHeader></SiteHeader>
    <div class="container-fluid small" style="background-color: rgb(244, 244, 244);">

<div class="metaportal_fn_mint_top row pad50">
    <section class="content-section" style="padding-top: 10px;
padding-bottom: 0px;">

        <div class="container-fluid">
            <div class=" justify-content-center">
                <div class=" row">
                    <!-- CSS Ticket inspired by -->

                    <div class="col-md-12">
                        <h3 class="texh3">REFUND POLICY</h3> 
<div class="col-sm-12">
<div class="hed">Returns</div>
<p>To be eligible for a return, there should have been a show cancel or Failure of Ticket generation .</p>
<div class="hed">Refunds (if applicable)</div>
<p>Once Refund eligable  we will send you an email to notify you that we have refunded your Money. 
</p>

<p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of 5 to 7 working days.
</p>
<div class="hed">Late or missing refunds (if applicable)</div>


<p>   If you haven’t received a refund yet, first check your bank account again.
</p>
<p>Then contact your credit card company, it may take some time before your refund is officially posted.</p>
<p>Next contact your bank. There is often some processing time before a refund is posted.
.</p>
<p style="padding-bottom: 20px;">If you’ve done all of this and you still have not received your refund yet, please contact us at 
    dreambigcinemas@movieclicks.in
</p>
</div>
</div>
</div>
</div>
</div>
</section>
</div>

</div>



    <SiteFooter></SiteFooter>
</template>
<script>

import SiteHeader from '../UI/SiteHeader.vue';
import SiteFooter from '../UI/SiteFooter.vue';
import SiteProgress from '../UI/SiteProgress.vue';
export default {
    components: {

        SiteHeader,
        SiteFooter,
        SiteProgress,

    }
}
</script>
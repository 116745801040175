<template>
    
<section class="feat-card section-padding" data-scroll-index="1" v-if="movies.length>0" >
<div class="container">
    <div class="mb-30">
        <div class="tabs">
<ul>
<li :class="currentTab==0?'is-active':''"><a @click="currentTab=0" >Showtime</a></li>
</ul>
</div>
    </div>

    <!-- MOVIES ENDS -->
<!-- SHOWTIME -->
<div id="showTimeWrpr" v-if="currentTab==0" class="tab-pane active">
<div class="col-md-12" >
<div class="nopad" v-if="!showtimeLoader">
<DatePaginator :totalPages="lastPage" :currentPage="currentPage" @pagechanged="onPageChange" :dates="datePages"></DatePaginator>
</div>
<div v-if="dateMovies">



<div class="col-xs-12 col-sm-6 col-md-6 marg10 bor0" style="background-color: #efefef;" v-for="(movie,mkey) in dateMovies" :key="mkey">
<div class="poster" >

<a href="#" tabindex="0">
<img class="img-responsive fulwid bor0" :src="path+'medium/'+movie.banner"  :alt="movie.title">
</a>
</div>
<div class="col-xs-12 col-sm-12 col-md-12   nambg borbom">
<div class="row">
<div class="col-10  ">
<h4 class="movnam shonam" style="padding-left: 10px;padding-top: 5px;">{{StripName(movie.title)}} <span style="font-size: 12px;color: red;">{{getLang(Languages,movie.lang)}}</span><span class="shod shola">{{ movie.diamen}}</span></h4></div>

<div class="col-2 text-end "> <span class="certificate2 reda pull-right ">{{ movie.cert}}</span></div>

</div>
</div>


<div class="col-xs-12 col-sm-12 col-md-12  nambg" v-if="dateShows[mkey]">
<div class="marg10">
<!-- <span class="vienam"> Viewing times </span>  -->
<a href="#" data-id="show1052"  v-for="(show,skey) in dateShows[mkey]" :key="skey" @click="gotoSeatLayout(show.id,show.isonline)"
      :class="(!show.isonline ?'':'disabled') +' text-center tim bg4'"
     :style="$route.params.showid==show.id?{'background-color':'#ffff00'}:{ 'background-color': fillColor(show.booked,show.seats,show.isonline) }">{{ show.stime}}<br><div class="" style="text-transform: uppercase;
font-size: 6px;">{{ show.exp}}</div>   </a>
  </div>
</div>

</div>
</div>
</div>
</div>
<!-- SHOWTIME ENDS -->

</div>
</section>

<div :class="'modal-mask'+(showModal?' show':'')" @click="showModal=false"><div class="modal-wrapper"><div class="modal-container"><h5 class="text-primary text-center">SORRY</h5><div class="text-danger">{{ warnigText}}</div></div></div></div>
</template>

<script>
import axios from 'axios';
import { URL,UPLOADS } from '@/Conf';
import DatePaginator from '../MovieDetails/DatePaginator.vue';

export default {
    data:()=>({
        isLoading:true,
        showtimeLoader:true,
        currentTab:0,
        showModal:false,
        warnigText:null,
        movies:[],
        allShows:[],
        dateMovies:[],
        dateShows:[],
        path:UPLOADS,
        certclass:{"U":"ce1","U/A":"ce2","A":"ce3"},
        Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
        days:{"0":"Sun","1":"Mon","2":"Tue","3":"Wed","4":"Thu","5":"Fri","6":"Sat"},
      months:{"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"},
        currentPage:0,
      lastPage:0,
        showDates:[],
      showTimes:[],
      bookedSeats:{},
      currentShowtime:[],
      datePages:{},
        locid:localStorage.getItem("locid")
    }),
    computed:{
        StripName : ()=>{
            return (MovieTitle) => {
            return MovieTitle.length>17?MovieTitle.substr(0,17)+"..":MovieTitle;
        }

      },
      getLang : ()=>{
            return (langs,key) => {
            return langs[key]
        }

      },


    },
    updated() {
      
  },
  components:{DatePaginator},

    methods:{
        getMovies(){
        this.locid=1
        if(this.locid)
        {axios.get(URL + "movies/"+localStorage.getItem("locid")).then((response) => {
                this.movies = response.data.result;
              }).then(()=>{
                this.isLoading=false;                
                this.$emit("axloaded");

              })}
        },
        getAllShows(){
            axios.get(URL + "allShows/"+localStorage.getItem("locid")).then((response) => {
                this.allShows = response.data.result;
                this.showDates=Object.keys(response.data.result.dates);
              }).then(()=>{
                this.isLoading=false;
                this.showtimeLoader=false;
        this.populatePage();
        this.onPageChange(this.currentPage);
        this.getBooked();
              })
        },
        getBooked(){
       axios.get(URL + "seatcount/"+localStorage.getItem("locid")).then((response) => {
        for(var dt in this.allShows.showtime){
            for(var mv in this.allShows.showtime[dt]){
                for(var sh of this.allShows.showtime[dt][mv]){
                    var booked=response.data.result.booked[sh.id];
                    sh["booked"]=booked;
            }
            }
        }
        
      })
      },
        populatePage(){
        this.datePages=[];
        this.lastPage=this.showDates.length;
        for(var i=0;i<this.lastPage;i++){
         var splited=this.showDates[i].split("-");
           var pg={"day":this.days[splited[3]]+" "+splited[2],"mth":this.months[splited[1]]}
           this.datePages.push(pg); 
        }
    },
    onPageChange(page) {
      this.currentPage = page;
      var curdt=this.showDates[page];
    this.dateMovies=this.allShows.movies[curdt];
    this.dateShows=this.allShows.showtime[curdt];
    },
    gotoSeatLayout(id,isonline){
      this.showModal=isonline;
      this.warnigText="Specified show tickets not available online.Please visit the box office!!";
      if(isonline==0){
        this.$router.push("../book-ticket/"+id);
      }
    },
  fillColor(cnt,seats,ison){
    if(ison!=0){
      return 'rgb(204,204,204)';
    }
  var blprc=cnt/seats;  
  var r = 255,
  g = Math.floor((255 * (1-blprc)));
  g=g>255?255:g;
  var b=g;
  return 'rgb('+r+','+g+','+b+')';
}
},
    created() {
       this.getMovies();
       this.getAllShows();
  }
}
</script>